<template>
  <div class="menubook-personalisation-container">
    <div class="menubook-personalisation-side-bar">
      <BaseButton
        :disabled="(logoFile === null && editedEqualsOriginal) || updating"
        @buttonPressed="updateAll(logoFile)"
      >
        <span v-if="updating"> Updating... </span>
        <span v-else-if="logoFile === null && editedEqualsOriginal">Up-to-date</span>
        <span v-else>Update</span>
      </BaseButton>
      <!--
      <BaseButton @buttonPressed="$router.push('/portal/menuboekPreview')">
        Bekijk preview
      </BaseButton>
      -->
    </div>
    <div class="menubook-personalisation-content">
      <h1>Menuboek personalisatie</h1>
      <div class="personalisation-items">
        <div class="personalisation-item">
          <p class="label">Achtergrond kleur</p>
          <input
            class="color-input"
            type="color"
            id="background-color"
            name="background-color"
            :value="config.backgroundColor"
            @change="
              updateConfig({
                configName: 'backgroundColor',
                configValue: $event.target.value,
              })
            "
          />
        </div>
        <div class="personalisation-item">
          <p class="label">Tekst kleur</p>
          <input
            class="color-input"
            type="color"
            id="text-color"
            name="text-color"
            :value="config.color"
            @change="
              updateConfig({
                configName: 'color',
                configValue: $event.target.value,
              })
            "
          />
        </div>
        <div class="personalisation-item">
          <p class="label">Logo</p>
          <label for="logo-image" class="file-input-btn">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'file-image']" /></span
          ></label>
          <input
            type="file"
            class="file-input"
            id="logo-image"
            name="image"
            accept="image/png, image/jpeg"
            @change="changeFile"
          />
          <div class="preview">
            <img v-if="logoPreview !== null" :src="logoPreview" />
            <img
              v-else-if="config.logoId !== undefined"
              :src="api_url + 'image/' + config.logoId"
              alt=""
            />
            <p v-else>Geen logo gevonden</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
const { api_url } = require("../../config/url");

export default {
  name: "Personalisation",
  components: {
    BaseButton,
  },
  data() {
    return {
      logoPreview: null,
      logoFile: null,
      api_url: api_url,
      updating: false,
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.menuboekAdmin.configEdited,
    }),
    ...mapGetters({
      editedEqualsOriginal: "menuboekAdmin/editedEqualsOriginalConfig",
    }),
  },
  methods: {
    ...mapMutations({
      updateConfig: "menuboekAdmin/updateMenubookConfigItem",
    }),
    ...mapActions({
      saveConfig: "menuboekAdmin/updateConfig",
    }),
    changeFile(event) {
      if (event.target.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.logoPreview = e.target.result;
        };
        this.logoFile = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    async updateAll(logoFile) {
      this.updating = true;
      await this.saveConfig(logoFile);
      this.logoFile = null;
      this.updating = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.menubook-personalisation-container {
  display: grid;
  grid-template-columns: 240px auto;
  overflow: hidden;
  height: 100%;
  .menubook-personalisation-side-bar {
    border-right: 1px solid $lm-divider-color;
    margin-top: calc(#{$standard-margin}* 2);
    button {
      margin-bottom: $standard-margin;
    }
  }
  .menubook-personalisation-content {
    margin-left: calc(#{$standard-margin}* 2);
    overflow: hidden;

    h1 {
      margin-top: calc(#{$standard-margin}* 2);
      text-align: center;
    }

    .personalisation-items {
      padding-top: $standard-padding;
      .personalisation-item {
        min-height: 50px;
        display: grid;
        grid-template-columns: 200px auto;
        .label {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          font-weight: 500;
        }
        .color-input {
          margin-left: $standard-margin;
          height: 50px;
          width: 50px;
          border: none;
          -webkit-appearance: none;
          padding: 0;
          background-color: rgba(#000000, 0);
          cursor: pointer;
        }
        .color-input::-webkit-color-swatch {
          border: 1px solid $lm-divider-color;
          border-radius: $standard-radius;
        }
        .file-input {
          visibility: hidden;
          display: none;
        }
        .file-input-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: $standard-margin;
          box-sizing: border-box;
          height: 50px;
          width: 50px;
          border-radius: $standard-radius;
          border: 3px solid $lm-background-color;
          background-color: $lm-divider-color;
          cursor: pointer;
          color: $lm-text-color;
          transition: color 0.2s ease, background-color 0.2s ease;
          .icon {
            font-size: 1.3rem;
          }
        }
        .file-input-btn:hover {
          background-color: darken($lm-divider-color, 3%);
          color: darken($lm-text-color, 10%);
        }
        .preview {
          margin-top: $standard-margin;
          max-width: 120px;
          max-height: 120px;
          background: linear-gradient(
            90deg,
            rgba(171, 171, 171, 1) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(171, 171, 171, 1) 100%
          );

          p {
            padding: 10px;
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
</style>
